import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "รายละเอียดของคอร์ส",
      "style": {
        "position": "relative"
      }
    }}>{`รายละเอียดของคอร์ส`}<a parentName="h1" {...{
        "href": "#%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%A5%E0%B8%B0%E0%B9%80%E0%B8%AD%E0%B8%B5%E0%B8%A2%E0%B8%94%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA",
        "aria-label": "รายละเอียดของคอร์ส permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
 คอร์สการสร้างเว็บไซต์ Portfolio สำหรับนักเรียน นักศึกษา
    </LeadParagraph>
    <Image size="M" src="/images/services/porfolio101.png" alt="Portfolio" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} mdxType="Image" />
    <h2 {...{
      "id": "เกี่ยวกับคอร์สเรียน",
      "style": {
        "position": "relative"
      }
    }}>{`เกี่ยวกับคอร์สเรียน`}<a parentName="h2" {...{
        "href": "#%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99",
        "aria-label": "เกี่ยวกับคอร์สเรียน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`คอร์สนี้มุ่งเน้นให้นักเรียน นักศึกษา สามารถสร้างเว็บไซต์ Portfolio ส่วนตัวที่สวยงาม ทันสมัย และแสดงผลงานได้อย่างมีประสิทธิภาพ โดยใช้ Astro.js ซึ่งเป็น framework ที่เน้นประสิทธิภาพและความเร็วในการโหลดเว็บไซต์ (Performance-focused). ผู้เรียนจะได้เรียนรู้ตั้งแต่พื้นฐานการติดตั้ง Astro.js, โครงสร้างโปรเจกต์, การสร้าง Layout, การแสดงผลงาน, การปรับแต่ง CSS, และการ Deploy เว็บไซต์`}</p>
    <h3 {...{
      "id": "คอร์สนี้เหมาะกับใคร",
      "style": {
        "position": "relative"
      }
    }}>{`คอร์สนี้เหมาะกับใคร`}<a parentName="h3" {...{
        "href": "#%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B9%80%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%83%E0%B8%84%E0%B8%A3",
        "aria-label": "คอร์สนี้เหมาะกับใคร permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`บุคคลทั่วไปที่ต้องการเรียนเพื่อนำความรู้ไปสร้างเว็ปไซต์เก็บ Portfolio ของตัวเองหรือบริษัท`}</li>
      <li parentName="ul">{`น้องๆนักเรียนทั่วไป ห้อง SmartCom,SMTE,Gifted เรียนเพื่อนำความรู้ไปสร้างเว็ปไซต์เก็บ Portfolio เพื่อแสดงผลงาน`}</li>
      <li parentName="ul">{`เมื่อเรียนจบแล้วจะได้ผลงานของตัวเองไปใช้งานได้จริง หรือสามารถต่อยอดรับจ้างทำเว็ปไซต์ได้หลังจากฝึกฝนจนเชี่ยวชาญ`}</li>
    </ul>
    <h3 {...{
      "id": "เนื้อหาเกี่ยวกับคอร์สนี้",
      "style": {
        "position": "relative"
      }
    }}>{`เนื้อหาเกี่ยวกับคอร์สนี้`}<a parentName="h3" {...{
        "href": "#%E0%B9%80%E0%B8%99%E0%B8%B7%E0%B9%89%E0%B8%AD%E0%B8%AB%E0%B8%B2%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B8%99%E0%B8%B5%E0%B9%89",
        "aria-label": "เนื้อหาเกี่ยวกับคอร์สนี้ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ผู้เรียนสามารถเข้าใจแนวคิดพื้นฐานของ Static Site Generator (SSG) และประโยชน์ของ Astro.js`}</li>
      <li parentName="ul">{`ผู้เรียนสามารถติดตั้งและตั้งค่าโปรเจกต์ Astro.js ได้`}</li>
      <li parentName="ul">{`ผู้เรียนสามารถสร้างโครงสร้างเว็บไซต์ Portfolio ที่เหมาะสมและใช้งานง่าย`}</li>
      <li parentName="ul">{`ผู้เรียนสามารถแสดงผลงาน (Projects) ในรูปแบบต่างๆ ได้ (เช่น รูปภาพ, วิดีโอ, ลิงก์)`}</li>
      <li parentName="ul">{`ผู้เรียนสามารถปรับแต่งรูปลักษณ์ของเว็บไซต์ด้วย CSS Modules และ Tailwind CSS (เลือกอย่างใดอย่างหนึ่ง)`}</li>
      <li parentName="ul">{`ผู้เรียนสามารถเพิ่มส่วนต่างๆ ที่จำเป็นสำหรับ Portfolio เช่น หน้าเกี่ยวกับตัว, ข้อมูลติดต่อ`}</li>
      <li parentName="ul">{`ผู้เรียนสามารถ Deploy เว็บไซต์ Portfolio ที่สร้างเสร็จไปยังแพลตฟอร์มฟรีได้`}</li>
      <li parentName="ul">{`ระยะเวลาเรียน: 20 ชั่วโมง (สามารถปรับตามความเร็วในการเรียนรู้ของผู้เรียน)`}</li>
    </ul>
    <h3 {...{
      "id": "เรียนก่อน-เป็นก่อน-ล้ำหน้ากว่าใครๆ",
      "style": {
        "position": "relative"
      }
    }}>{`เรียนก่อน เป็นก่อน ล้ำหน้ากว่าใครๆ`}<a parentName="h3" {...{
        "href": "#%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99-%E0%B9%80%E0%B8%9B%E0%B9%87%E0%B8%99%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99-%E0%B8%A5%E0%B9%89%E0%B8%B3%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B8%81%E0%B8%A7%E0%B9%88%E0%B8%B2%E0%B9%83%E0%B8%84%E0%B8%A3%E0%B9%86",
        "aria-label": "เรียนก่อน เป็นก่อน ล้ำหน้ากว่าใครๆ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h3 {...{
      "id": "พื้นฐาน-astrojs-และโครงสร้างเว็บไซต์",
      "style": {
        "position": "relative"
      }
    }}>{`พื้นฐาน Astro.js และโครงสร้างเว็บไซต์`}<a parentName="h3" {...{
        "href": "#%E0%B8%9E%E0%B8%B7%E0%B9%89%E0%B8%99%E0%B8%90%E0%B8%B2%E0%B8%99-astrojs-%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B9%82%E0%B8%84%E0%B8%A3%E0%B8%87%E0%B8%AA%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9A%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C",
        "aria-label": "พื้นฐาน astrojs และโครงสร้างเว็บไซต์ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h3 {...{
      "id": "ชั่วโมงที่-1-2-ทำความรู้จักกับ-astrojs-และ-ssg-ทฤษฎีและปฏิบัติ",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 1-2: ทำความรู้จักกับ Astro.js และ SSG (ทฤษฎีและปฏิบัติ)`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-1-2-%E0%B8%97%E0%B8%B3%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%A3%E0%B8%B9%E0%B9%89%E0%B8%88%E0%B8%B1%E0%B8%81%E0%B8%81%E0%B8%B1%E0%B8%9A-astrojs-%E0%B9%81%E0%B8%A5%E0%B8%B0-ssg-%E0%B8%97%E0%B8%A4%E0%B8%A9%E0%B8%8E%E0%B8%B5%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4",
        "aria-label": "ชั่วโมงที่ 1 2 ทำความรู้จักกับ astrojs และ ssg ทฤษฎีและปฏิบัติ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ความหมายและหลักการทำงานของ Static Site Generator (SSG)`}</li>
      <li parentName="ul">{`ทำไมต้องเลือก Astro.js? (ประสิทธิภาพ, Component Islands, รองรับ Frameworks อื่นๆ)`}</li>
      <li parentName="ul">{`การเปรียบเทียบ Astro.js กับ Frameworks อื่นๆ (เช่น Next.js, Gatsby) ในบริบทของ Portfolio`}</li>
      <li parentName="ul">{`ข้อดีและข้อเสียของ Astro.js`}</li>
      <li parentName="ul">{`ติดตั้ง Node.js และ npm/yarn`}</li>
      <li parentName="ul">{`ติดตั้ง Astro CLI (npm create astro@latest หรือ yarn create astro)`}</li>
      <li parentName="ul">{`สร้างโปรเจกต์ Astro.js พื้นฐาน`}</li>
    </ul>
    <h3 {...{
      "id": "ชั่วโมงที่-3-4-โครงสร้างโปรเจกต์-astrojs-ปฏิบัติ",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 3-4: โครงสร้างโปรเจกต์ Astro.js (ปฏิบัติ)`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-3-4-%E0%B9%82%E0%B8%84%E0%B8%A3%E0%B8%87%E0%B8%AA%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B9%80%E0%B8%88%E0%B8%81%E0%B8%95%E0%B9%8C-astrojs-%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4",
        "aria-label": "ชั่วโมงที่ 3 4 โครงสร้างโปรเจกต์ astrojs ปฏิบัติ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ทำความเข้าใจโครงสร้างโฟลเดอร์ (src, public, package.json, astro.config.mjs)`}</li>
      <li parentName="ul">{`ส่วนประกอบหลักของ Astro: Components (.astro), Layouts (.astro), Pages (.astro), Content Collections (.mdx หรือ .md)`}</li>
      <li parentName="ul">{`การสร้าง Layout Component พื้นฐาน (src/layouts/BaseLayout.astro)`}</li>
      <li parentName="ul">{`การใช้งาน Slots ใน Layout`}</li>
      <li parentName="ul">{`การเพิ่ม Meta Tags (Title, Description)`}</li>
    </ul>
    <h3 {...{
      "id": "ชั่วโมงที่-5-6-การสร้างหน้าแรก-homepage-ปฏิบัติ",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 5-6: การสร้างหน้าแรก (Homepage) (ปฏิบัติ)`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-5-6-%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B9%81%E0%B8%A3%E0%B8%81-homepage-%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4",
        "aria-label": "ชั่วโมงที่ 5 6 การสร้างหน้าแรก homepage ปฏิบัติ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`การสร้าง Page Component (src/pages/index.astro)`}</li>
      <li parentName="ul">{`การแสดงข้อมูลส่วนตัวเบื้องต้น (ชื่อ, คำโปรยสั้นๆ)`}</li>
      <li parentName="ul">{`การเพิ่มรูปภาพโปรไฟล์ (public folder และการใช้งาน img ใน Astro)`}</li>
      <li parentName="ul">{`การสร้างส่วน "เกี่ยวกับตัว" (About Me) อย่างง่าย`}</li>
      <li parentName="ul">{`การใช้ Astro Components ในการจัดโครงสร้างหน้า`}</li>
    </ul>
    <h3 {...{
      "id": "ชั่วโมงที่-7-8-การสร้างส่วนแสดงผลงาน-projects-section-ปฏิบัติ",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 7-8: การสร้างส่วนแสดงผลงาน (Projects Section) (ปฏิบัติ)`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-7-8-%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B9%81%E0%B8%AA%E0%B8%94%E0%B8%87%E0%B8%9C%E0%B8%A5%E0%B8%87%E0%B8%B2%E0%B8%99-projects-section-%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4",
        "aria-label": "ชั่วโมงที่ 7 8 การสร้างส่วนแสดงผลงาน projects section ปฏิบัติ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`แนวคิดการจัดการผลงาน (Static Data หรือ Content Collections)`}</li>
      <li parentName="ul">{`สร้าง Array ข้อมูลผลงานเบื้องต้นใน Page Component (index.astro)`}</li>
      <li parentName="ul">{`การวนลูปแสดงผลงานด้วย { } ใน Astro Components`}</li>
      <li parentName="ul">{`การแสดงชื่อผลงาน, คำอธิบายสั้นๆ, และรูปภาพตัวอย่าง`}</li>
    </ul>
    <h3 {...{
      "id": "ชั่วโมงที่-9-10",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 9-10:`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-9-10",
        "aria-label": "ชั่วโมงที่ 9 10 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`การใช้ Content Collections สำหรับผลงาน (ปฏิบัติ)`}</li>
      <li parentName="ul">{`สร้าง Content Collection สำหรับเก็บข้อมูลผลงาน (src/content/config.ts)`}</li>
      <li parentName="ul">{`สร้าง Markdown หรือ MDX ไฟล์สำหรับแต่ละผลงาน (src/content/projects/)`}</li>
      <li parentName="ul">{`การดึงและแสดงผลข้อมูลจาก Content Collections ใน Page Component`}</li>
      <li parentName="ul">{`การใช้ getCollection() และการเข้าถึงข้อมูล Frontmatter`}</li>
    </ul>
    <h3 {...{
      "id": "ชั่วโมงที่-11-12",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 11-12:`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-11-12",
        "aria-label": "ชั่วโมงที่ 11 12 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`การสร้างหน้าแสดงผลงานแต่ละชิ้น (Project Detail Page) (ปฏิบัติ)`}</li>
      <li parentName="ul">{`สร้าง Dynamic Routes ใน Astro (src/pages/projects/`}{`[slug]`}{`.astro)`}</li>
      <li parentName="ul">{`การดึงข้อมูลผลงานตาม slug`}</li>
      <li parentName="ul">{`การแสดงรายละเอียดผลงานทั้งหมด (ชื่อ, คำอธิบาย, รูปภาพ/วิดีโอเพิ่มเติม, ลิงก์)`}</li>
    </ul>
    <h3 {...{
      "id": "ชั่วโมงที่-13-14-การปรับแต่งด้วย-css-modules-ปฏิบัติ",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 13-14: การปรับแต่งด้วย CSS Modules (ปฏิบัติ)`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-13-14-%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9B%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B9%81%E0%B8%95%E0%B9%88%E0%B8%87%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2-css-modules-%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4",
        "aria-label": "ชั่วโมงที่ 13 14 การปรับแต่งด้วย css modules ปฏิบัติ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`การสร้างไฟล์ CSS Modules (.module.css)`}</li>
      <li parentName="ul">{`การ Import และใช้งาน CSS Modules ใน Astro Components`}</li>
      <li parentName="ul">{`การจัดการ Styles ในแต่ละ Component อย่างเป็นระบบ`}</li>
      <li parentName="ul">{`การปรับแต่ง Layout และส่วนต่างๆ ของเว็บไซต์ให้สวยงาม`}</li>
    </ul>
    <h3 {...{
      "id": "ชั่วโมงที่-15-16-การปรับแต่งด้วย-tailwind-css-เลือกอย่างใดอย่างหนึ่ง-ปฏิบัติ",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 15-16: การปรับแต่งด้วย Tailwind CSS (เลือกอย่างใดอย่างหนึ่ง) (ปฏิบัติ)`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-15-16-%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9B%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B9%81%E0%B8%95%E0%B9%88%E0%B8%87%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2-tailwind-css-%E0%B9%80%E0%B8%A5%E0%B8%B7%E0%B8%AD%E0%B8%81%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%83%E0%B8%94%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B8%AB%E0%B8%99%E0%B8%B6%E0%B9%88%E0%B8%87-%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4",
        "aria-label": "ชั่วโมงที่ 15 16 การปรับแต่งด้วย tailwind css เลือกอย่างใดอย่างหนึ่ง ปฏิบัติ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ติดตั้งและตั้งค่า Tailwind CSS ใน Astro (npx astro add tailwind)`}</li>
      <li parentName="ul">{`ทำความเข้าใจหลักการทำงานของ Tailwind CSS (Utility-First CSS)`}</li>
      <li parentName="ul">{`การใช้งาน Classes ของ Tailwind CSS ใน Astro Components`}</li>
      <li parentName="ul">{`การปรับแต่ง Layout และส่วนต่างๆ ของเว็บไซต์ให้สวยงามด้วย Tailwind Classes`}</li>
    </ul>
    <h3 {...{
      "id": "ชั่วโมงที่-17-18-การสร้างหน้า-เกี่ยวกับตัว-about-page-และ-ติดต่อ-contact-page-ปฏิบัติ",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 17-18: การสร้างหน้า "เกี่ยวกับตัว" (About Page) และ "ติดต่อ" (Contact Page) (ปฏิบัติ)`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-17-18-%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%A3%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2-%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%95%E0%B8%B1%E0%B8%A7-about-page-%E0%B9%81%E0%B8%A5%E0%B8%B0-%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD-contact-page-%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4",
        "aria-label": "ชั่วโมงที่ 17 18 การสร้างหน้า เกี่ยวกับตัว about page และ ติดต่อ contact page ปฏิบัติ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`สร้าง Page Components สำหรับหน้า "เกี่ยวกับตัว" (src/pages/about.astro) และ "ติดต่อ" (src/pages/contact.astro)`}</li>
      <li parentName="ul">{`การแสดงข้อมูลเกี่ยวกับประวัติส่วนตัว, ความสนใจ, ทักษะ`}</li>
      <li parentName="ul">{`การเพิ่มฟอร์มติดต่ออย่างง่าย (อาจใช้บริการภายนอก เช่น Formspree หรือ Netlify Forms) หรือแสดงข้อมูลติดต่อ (อีเมล, LinkedIn)`}</li>
      <li parentName="ul">{`การใช้ Links (Link component ของ Astro) ในการนำทางระหว่างหน้า`}</li>
    </ul>
    <h3 {...{
      "id": "ชั่วโมงที่-19-20-การ-deploy-เว็บไซต์-portfolio-ปฏิบัติ",
      "style": {
        "position": "relative"
      }
    }}>{`ชั่วโมงที่ 19-20: การ Deploy เว็บไซต์ Portfolio (ปฏิบัติ)`}<a parentName="h3" {...{
        "href": "#%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88-19-20-%E0%B8%81%E0%B8%B2%E0%B8%A3-deploy-%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9A%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C-portfolio-%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4",
        "aria-label": "ชั่วโมงที่ 19 20 การ deploy เว็บไซต์ portfolio ปฏิบัติ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ทำความเข้าใจกระบวนการ Build ของ Astro (npm run build หรือ yarn build)`}</li>
      <li parentName="ul">{`แนะนำแพลตฟอร์ม Deploy ฟรีสำหรับ Static Sites (เช่น Netlify, Vercel, GitHub Pages)`}</li>
      <li parentName="ul">{`การ Deploy เว็บไซต์ไปยังแพลตฟอร์มที่เลือก`}</li>
      <li parentName="ul">{`การตั้งค่า Custom Domain (ถ้ามี)`}</li>
      <li parentName="ul">{`สรุปเนื้อหาคอร์สและตอบคำถามเพิ่มเติม`}</li>
      <li parentName="ul">{`แนวทางการพัฒนา Portfolio ต่อไป`}</li>
    </ul>
    <h3 {...{
      "id": "การสอนเพิ่มเติม",
      "style": {
        "position": "relative"
      }
    }}>{`การสอนเพิ่มเติม`}<a parentName="h3" {...{
        "href": "#%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%AD%E0%B8%99%E0%B9%80%E0%B8%9E%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B9%80%E0%B8%95%E0%B8%B4%E0%B8%A1",
        "aria-label": "การสอนเพิ่มเติม permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`เครื่องมือสร้างรูปผ่าน AI`}</li>
      <li parentName="ul">{`โปรแกรม Canva AI`}</li>
      <li parentName="ul">{`โปรแกรม Photoshop`}</li>
    </ul>
    <h3 {...{
      "id": "อาจารย์ผู้สอน",
      "style": {
        "position": "relative"
      }
    }}>{`อาจารย์ผู้สอน`}<a parentName="h3" {...{
        "href": "#%E0%B8%AD%E0%B8%B2%E0%B8%88%E0%B8%B2%E0%B8%A3%E0%B8%A2%E0%B9%8C%E0%B8%9C%E0%B8%B9%E0%B9%89%E0%B8%AA%E0%B8%AD%E0%B8%99",
        "aria-label": "อาจารย์ผู้สอน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`อ.แฟรงค์ นักพัฒนาซอฟแวร์ประสบการณ์พัฒนาซอฟแวร์ 10 ปี +`}</li>
      <li parentName="ul">{`อ.แทน  นักพัฒนาซอฟแวร์ประสบการณ์พัฒนาซอฟแวร์ 10 ปี +
เชี่ยวชาญการออกแบบเว็ปไซต์ให้กับองค์กรชั้นนำระดับประเทศ`}</li>
      <li parentName="ul">{`อ.เอก กราฟฟิค designer ประสบการณ์กว่า 5 ปี +`}</li>
    </ul>
    <h3 {...{
      "id": "จำนวน-20-ชั่วโมง",
      "style": {
        "position": "relative"
      }
    }}>{`จำนวน 20 ชั่วโมง`}<a parentName="h3" {...{
        "href": "#%E0%B8%88%E0%B8%B3%E0%B8%99%E0%B8%A7%E0%B8%99-20-%E0%B8%8A%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%82%E0%B8%A1%E0%B8%87",
        "aria-label": "จำนวน 20 ชั่วโมง permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`ค่าใช้จ่าย 5600 บาท`}</li>
      <li parentName="ul">{`ระยะเวลาเรียนทุกวันเสาร์ วันละ 2 ชั่วโมง ระยะเวลา 2 เดือน(หรือ 8 สัปดาห์)`}</li>
      <li parentName="ul">{`สามารถเลือกเรียนตามรอบที่ต้องการได้`}</li>
      <li parentName="ul">{`รอบเช้า 10.00 - 12.00 `}</li>
      <li parentName="ul">{`รอบบ่าย 14.00 - 16.00`}</li>
      <li parentName="ul">{`เรียนผ่านโปรแกรมซูม `}</li>
      <li parentName="ul">{`เรียนซ้ำได้จนกว่าจะเข้าใจ`}</li>
      <li parentName="ul">{`สำหรับรายบุคคลหรือหน่วยงานที่ต้องการสอนแยกทำ workshop เฉพาะ สอนเป็นรายบุคคลหรือหน่วยงานติดต่อได้ที่ครูแฟรงค์`}</li>
    </ul>
    <h3 {...{
      "id": "การสมัครเรียน",
      "style": {
        "position": "relative"
      }
    }}>{`การสมัครเรียน`}<a parentName="h3" {...{
        "href": "#%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99",
        "aria-label": "การสมัครเรียน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`โทร: 086-943-1115`}</li>
      <li parentName="ul">{`ติดต่อผ่าน Email: `}<a parentName="li" {...{
          "href": "mailto:anndream.com@gmail.com"
        }}>{`anndream.com@gmail.com`}</a>{` `}</li>
      <li parentName="ul">{`line : frankpkth`}</li>
      <li parentName="ul">{`จะได้รับการติดต่อกลับภายใน 24 ชั่วโมง เพื่อนัดการจ่ายเงินก่อนเริ่มนัดหมายการสอน`}</li>
    </ul>
    <h3 {...{
      "id": "โปรดระวังมิจฉาชีพทุกกรณีทางเราจะไม่มีการส่ง-link-ใดๆไปให้ลูกค้ากดเด็ดขาด",
      "style": {
        "position": "relative"
      }
    }}><strong parentName="h3"><em parentName="strong">{`โปรดระวังมิจฉาชีพทุกกรณีทางเราจะไม่มีการส่ง link ใดๆไปให้ลูกค้ากดเด็ดขาด`}</em></strong><a parentName="h3" {...{
        "href": "#%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B8%94%E0%B8%A3%E0%B8%B0%E0%B8%A7%E0%B8%B1%E0%B8%87%E0%B8%A1%E0%B8%B4%E0%B8%88%E0%B8%89%E0%B8%B2%E0%B8%8A%E0%B8%B5%E0%B8%9E%E0%B8%97%E0%B8%B8%E0%B8%81%E0%B8%81%E0%B8%A3%E0%B8%93%E0%B8%B5%E0%B8%97%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%88%E0%B8%B0%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%A1%E0%B8%B5%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B9%88%E0%B8%87-link-%E0%B9%83%E0%B8%94%E0%B9%86%E0%B9%84%E0%B8%9B%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%A5%E0%B8%B9%E0%B8%81%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B8%81%E0%B8%94%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94%E0%B8%82%E0%B8%B2%E0%B8%94",
        "aria-label": "โปรดระวังมิจฉาชีพทุกกรณีทางเราจะไม่มีการส่ง link ใดๆไปให้ลูกค้ากดเด็ดขาด permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h3 {...{
      "id": "ไม่มั่นใจให้ติดต่อผ่านเบอร์โทรศัพท์ก่อน",
      "style": {
        "position": "relative"
      }
    }}><strong parentName="h3"><em parentName="strong">{`ไม่มั่นใจให้ติดต่อผ่านเบอร์โทรศัพท์ก่อน`}</em></strong><a parentName="h3" {...{
        "href": "#%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%A1%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%83%E0%B8%88%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%9C%E0%B9%88%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%9A%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B9%82%E0%B8%97%E0%B8%A3%E0%B8%A8%E0%B8%B1%E0%B8%9E%E0%B8%97%E0%B9%8C%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99",
        "aria-label": "ไม่มั่นใจให้ติดต่อผ่านเบอร์โทรศัพท์ก่อน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h3 {...{
      "id": "เตรียมความพร้อมก่อนเรียน",
      "style": {
        "position": "relative"
      }
    }}>{`เตรียมความพร้อมก่อนเรียน`}<a parentName="h3" {...{
        "href": "#%E0%B9%80%E0%B8%95%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9E%E0%B8%A3%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%81%E0%B9%88%E0%B8%AD%E0%B8%99%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99",
        "aria-label": "เตรียมความพร้อมก่อนเรียน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`คอมพิวเตอร์หรือโน๊ตบุค`}</li>
      <li parentName="ul">{`เชื่อมต่อไวไฟ`}</li>
      <li parentName="ul">{`ห้องส่วนตัวหรือบริเวณที่ไม่เกิดเสียงดังรบกวนระหว่างเรียน`}</li>
      <li parentName="ul">{`โปรแกรมซูม`}</li>
      <li parentName="ul">{`สมุดดินสอ ปากกา`}</li>
      <li parentName="ul">{`ความตั้งใจ `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      